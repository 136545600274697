import { useEffect, useRef, useState } from "react";
import axios from "../api";
import biassonoLogo from "../../assets/Logo_Biassono.png";
import biassonoBg from "../../assets/BiassonoBg.jpg";
const useAdvertisingVideos = () => {
  const [data, setData] = useState(null);
  const videoRefs = useRef([]);
  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);
  const [isSingleVideo, setIsSingleVideo] = useState(false); // Aggiunto

  useEffect(() => {
    axios
      .get(`https://backend.biassono.bbsitalia.com/api/advertising-video`)
      .then((response) => {
        setData(response.data);
        setIsSingleVideo(response.data.rows.length === 1); // Aggiunto
        console.log("data received:", response.data);
      })
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    const currentVideo = videoRefs.current[currentVideoIndex];
    if (currentVideo) {
      currentVideo.addEventListener("ended", handleVideoEnd);
      currentVideo.play();
      console.log("video started, currentVideoIndex:", currentVideoIndex);
    }

    return () => {
      if (currentVideo) {
        currentVideo.removeEventListener("ended", handleVideoEnd);
        currentVideo.pause();
        console.log("video paused, currentVideoIndex:", currentVideoIndex);
      }
    };
  }, [currentVideoIndex, videoRefs, data]);

  const handleVideoEnd = () => {
    if (!isSingleVideo) {
      // Aggiunto
      setCurrentVideoIndex((currentVideoIndex + 1) % data.rows.length);
    } else {
      // Aggiunto
      const currentVideo = videoRefs.current[currentVideoIndex];
      currentVideo.currentTime = 0;
      currentVideo.play();
    }
  };

  return { data, videoRefs, currentVideoIndex };
};

const HeaderTotem = () => {
  const { data, videoRefs, currentVideoIndex } = useAdvertisingVideos();

  return (
    <div
      className={
        "bg-monrealeRed flex flex-wrap h-[20vh] justify-center items-center p-8 relative"
      }
    >
      <div
        className="flex flex-row justify-center items-center z-10"
        style={{
          backgroundColor: "white",
        }}
      >
        <img
          className={"w-[80px] h-auto m-auto relative z-10"}
          src={biassonoLogo}
          alt="Biassono Logo"
        />
      </div>

      <img
        src={biassonoBg}
        className={"w-full h-full object-cover absolute top-0 left-0 z-auto"}
      />
    </div>
  );
};

export default HeaderTotem;

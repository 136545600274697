import { useMediaQuery } from "react-responsive";
import { BackButtonWhite } from "../components/UI/BackButtonWhite";

const ExternalPage = () => {
  const isDesktop = useMediaQuery({
    query: "(min-width: 1024px)",
  });

  return (
    <section id={"externalLink"} className={"h-screen overflow-hidden"}>
      <div
        className={
          "flex flex-wrap h-[90vh] content-center relative flex overflow-hidden"
        }
      >
        <iframe
          src={"https://www.aebonline.it/"}
          title={"externalLink"}
          className={"w-full h-full"}
        ></iframe>
      </div>
      {isDesktop ? (
        <div
          className={
            "bg-biassonoAzure h-[10vh] b-t-l-r-15 content-center flex flex-wrap items-center p-8"
          }
        >
          <BackButtonWhite sfondo={"true"} />
        </div>
      ) : (
        <div className={"hidden"} />
      )}
    </section>
  );
};

export default ExternalPage;

import { Link } from "react-router-dom";
import { useEffect, useState } from "react";

import LogoAEB from "../../assets/LogoAEB.png";

import "./Menu.css";
import axios from "../api";
import LanguageBox from "./LanguageBox";

const Menu = () => {
  const currentLanguage = localStorage.getItem("i18nextLng")?.toLowerCase();
  // alert(currentLanguage)

  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [height, setHeight] = useState("0");
  const [top, setTop] = useState("[540px]");
  const [padding, setPadding] = useState("0");
  const [paddingTop, setPaddingTop] = useState("0");
  const [paddingBottom, setPaddingBottom] = useState("0");

  function toggleHeight() {
    setHeight(height === "0" ? "37vh" : "0");
    setTop(top === "[540px]" ? "0" : "[540px]");
    setPadding(padding === "0" ? "5" : "0");
    setPaddingTop(paddingTop === "0" ? "4" : "0");
    setPaddingBottom(paddingBottom === "0" ? "4" : "0");
  }

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    toggleHeight();
  };

  const [data, setData] = useState(null);
  const [extra, setExtra] = useState(null);

  useEffect(() => {
    axios
      .get(`https://backend.biassono.bbsitalia.com/${currentLanguage}/api/menu`)
      .then((response) => setData(response.data))
      .catch((error) => console.log(error));
  }, []);

  useEffect(() => {
    axios
      .get(
        `https://backend.biassono.bbsitalia.com/${currentLanguage}/api/menu_extra_totem`
      )
      .then((response) => setExtra(response.data))
      .catch((error) => console.log(error));
  }, []);

  return (
    <div
      className={
        "h-full flex flex-wrap items-center content-center bg-biassonoAzure z-20 relative justify-center"
      }
    >
      <div className={"w-full flex justify-center"}>
        <Link to={`/external-page`} className={"text-white"}>
          <img src={LogoAEB} alt={"LogoAEB"} style={{ width: "200px" }} />
        </Link>
      </div>
    </div>
  );
};

export default Menu;

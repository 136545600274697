import axios from "axios";
import { t } from "i18next";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { BarLoader } from "react-spinners";
import { BackButton } from "../UI/BackButton";
import AudioCard from "./AudioCard";
import VisibileConMonrealeCard from "../UI/VisibileConMonrealeCard";

const AudioPlaylist = () => {
  // get params from url
  const { id } = useParams();
  const [audioPlaylist, setAudioPlaylist] = useState([]);
  const [selectedAudio, setSelectedAudio] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const locationPlaylist = location?.state?.audioPlaylist;

  useEffect(() => {
    if (!!locationPlaylist) {
      setAudioPlaylist(locationPlaylist);
    } else {
      // in caso limite si refresha la pagina
      setIsLoading(true);
      axios
        .get(
          `https://backend.biassono.bbsitalia.com/api/audio/playlist/${id}?_format=json`
        )
        .then((res) => {
          setAudioPlaylist(res.data);
          setIsLoading(false);
        })
        .catch((err) => {
          console.log(err);
          setIsLoading(false);
        });
    }
  }, []);

  const filterUniqueAudio = useMemo(() => {
    return audioPlaylist.filter(
      (v, i, a) => a.findIndex((t) => t.mid_export === v.mid_export) === i
    );
  }, [audioPlaylist]);

  return (
    <div className={"h-screen overflow-hidden"}>
      {isLoading ? (
        <div className="flex justify-center items-center h-screen">
          <BarLoader color={"#2b6cb0"} size={50} />
        </div>
      ) : (
        <>
          <div
            className={
              "flex flex-wrap h-[12vh] content-end relative z-0 p-5 bg-monrealeRed"
            }
          >
            <div className={"flex flex-wrap items-center relative w-full  "}>
              <BackButton sfondo={"false"} />
              <span className={"text-white font-semibold text-[20px] ml-4"}>
                {t("Playlist audio")}
              </span>
            </div>
          </div>
          <div
            className={"flex flex-col items-center overflow-y-auto py-10 "}
            style={{
              maxHeight: "calc(100vh - 12vh)",
              minHeight: "calc(100vh - 12vh)",
            }}
          >
            {filterUniqueAudio.length > 0 && (
              <>
                <div className={"flex flex-col"}>
                  {filterUniqueAudio.map((audio, i) => (
                    <div
                      key={`video_${i}`}
                      style={{
                        display:
                          !!selectedAudio && selectedAudio !== audio.mid_export
                            ? "none"
                            : "block",
                      }}
                    >
                      <VisibileConMonrealeCard
                        withFanoCard={parseInt(audio.visibile_con_fanocard)}
                        customClass={"mb-3 border border-gray-400"}
                        customStyle={{
                          maxWidth: window.innerWidth - 50,
                          height: 200,
                          display:
                            !!selectedAudio &&
                            selectedAudio !== audio.mid_export
                              ? "none"
                              : "block",
                        }}
                      >
                        <AudioCard
                          selectedAudio={selectedAudio}
                          setSelectedAudio={setSelectedAudio}
                          audioUrl={audio.audio}
                          key={`audio_${i}`}
                          type={"Audio"}
                          setAudioPlaylist={setAudioPlaylist}
                          withFanoCard={parseInt(audio.visibile_con_fanocard)}
                          titolo={audio.titolo}
                          immagine_antemprima={audio.immagine_anteprima}
                          mid_export={audio.mid_export}
                        />
                      </VisibileConMonrealeCard>
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default AudioPlaylist;

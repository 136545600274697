import React, { useEffect, useRef, useState } from "react";
import axios from "../api";
import classNames from "classnames";
import dove from "../../assets/icons/dove.svg";
import calendario from "../../assets/icons/calendario.svg";
import { Link } from "react-router-dom";

const InEvidenzaCarouselTotem = () => {
  const [data, setData] = useState(null);
  const containerRef = useRef(null);
  const [currentSlide, setCurrentSlide] = useState(0);
  const currentLanguage = localStorage.getItem("i18nextLng")?.toLowerCase();

  useEffect(() => {
    axios
      .get(
        `https://backend.biassono.bbsitalia.com/${currentLanguage}/api/homepage/in-evidenza`
      )
      .then((response) => setData(response.data))
      .catch((error) => console.log(error));
  }, []);

  const handleScroll = () => {
    const container = containerRef.current;
    if (!container) return;
    const slideWidth = container.clientWidth / 3;
    const slideIndex = Math.round(container.scrollLeft / slideWidth / 2);
    setCurrentSlide(slideIndex);
  };

  const sliderClasses =
    "relative w-full flex gap-6 snap-x snap-mandatory overflow-x-auto pb-14 pt-4";
  const slideClasses =
    "flex-shrink-0 w-full rounded-lg overflow-hidden transform transition-all duration-500 ease-out";

  const getDetailLink = (location) => {
    if (!location || !location.type || !location.id) {
      return null;
    }
    let link = null;

    switch (location.type) {
      case "eventi":
        link = `/eventi-e-rassegne/${location.id}`;
        break;
      case "news":
        link = `/news/${location.id}`;
        break;
      case "rassegna":
        link = `/eventi-e-rassegne/${location.id}`;
        break;
    }

    return link;
  };

  return (
    <div
      className={sliderClasses}
      ref={containerRef}
      onScroll={handleScroll}
      // Aggiungi questa parte per aggiungere la classe "active" alla slide corrente
      style={{ scrollSnapAlign: "center" }}
    >
      <div className="snap-center shrink-0 -m-2">
        <div className="shrink-0 w-4 sm:w-12"></div>
      </div>
      {data?.rows?.map((inEvidenzaList, i) => (
        <div
          key={i}
          className={classNames(
            "snap-center shrink-0 first:ml-8 last:mr-8 transition-all -mr-3 w-[25%] h-full mr-10"
          )}
        >
          <Link to={`${getDetailLink(inEvidenzaList)}`}>
            <img
              className={classNames(
                "mb-5 w-full h-[400px] object-cover shadow-home"
              )}
              src={`https://backend.biassono.bbsitalia.com${inEvidenzaList.immagine_anteprima}`}
              alt="Slide"
            />
            <div className={"titolo break-words text-center"}>
              <h1 className="font-bold leading-tight mb-2 font-roboto_slab text-biassonoAzure text-[24px] uppercase">
                {inEvidenzaList.titolo}
              </h1>
            </div>
            {inEvidenzaList.dove && (
              <div className={"dove"}>
                <p className="text-gray-700 text-base leading-tight"></p>
                <img
                  src={dove}
                  alt="dove"
                  className={"w-4 inline-block mr-2"}
                />
                <span className="text-[18px] break-words">
                  {inEvidenzaList.dove?.slice(0, 30)}
                  {inEvidenzaList.dove?.length > 30 ? "..." : ""}
                </span>
              </div>
            )}

            {inEvidenzaList.data_inizio && inEvidenzaList.data_fine && (
              <div className={"data"}>
                <p className="text-gray-700 text-base leading-tight"></p>
                <img
                  src={calendario}
                  alt="calendario"
                  className={"w-4 inline-block mr-2"}
                />
                <span className="text-[18px]">
                  {inEvidenzaList.data_inizio}
                </span>{" "}
                -{" "}
                <span className="text-[18px]">{inEvidenzaList.data_fine}</span>
              </div>
            )}
          </Link>
        </div>
      ))}
      <div className="snap-center shrink-0">
        <div className="shrink-0 w-4 sm:w-48"></div>
      </div>
    </div>
  );
};

export default InEvidenzaCarouselTotem;

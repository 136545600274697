import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import axios from "../api";
import { BackButton } from "../UI/BackButton";
import doveDormire from "../../assets/icons/doveDormire.svg";
import calendarioIcon from "../../assets/icons/calendario.svg";
import doveIcon from "../../assets/icons/dove.svg";
import GoToDirection from "../UI/GoToDirection";
import { css } from "@emotion/react";
// import {CircleLoader} from "react-spinners";
import { BarLoader } from "react-spinners";
import { BackButtonWhite } from "../UI/BackButtonWhite";
import { useMediaQuery } from "react-responsive";
import { useTranslation } from "react-i18next";
import Gallery from "../gallery/Gallery";
import { openExternalLink } from "../../helpers/ExternalLinksFuncs";

export const SaporiEShoppingDetail = () => {
  const { t } = useTranslation();

  const { id } = useParams();
  const currentLanguage = localStorage.getItem("i18nextLng")?.toLowerCase();

  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [galleria, setGalleria] = useState([]);

  useEffect(() => {
    axios
      .get(
        `https://backend.biassono.bbsitalia.com/${currentLanguage}/api/sapori-e-shopping/${id}`
      )
      .then((response) => {
        setData(response.data[0]);
        setIsLoading(false);
        axios
          .get(
            `https://backend.biassono.bbsitalia.com/${currentLanguage}/api/media/galleria/${id}`
          )
          .then((response) => {
            setGalleria(response.data);
            setIsLoading(false);
          });
      })
      .catch((error) => console.log(error));
  }, []);

  let dataInizio = data.data_inizio;
  if (dataInizio) {
    const dateObjInizio = new Date(dataInizio);
    if (dateObjInizio.toString() !== "Invalid Date") {
      const dayInizio = dateObjInizio.getDate().toString().padStart(2, "0");
      const monthInizio = (dateObjInizio.getMonth() + 1)
        .toString()
        .padStart(2, "0"); // Nota: i mesi in JavaScript partono da 0, quindi aggiungiamo 1 al valore restituito
      const yearInizio = dateObjInizio.getFullYear().toString();
      dataInizio = `${dayInizio}/${monthInizio}/${yearInizio}`;
    } else {
      dataInizio = "";
    }
  } else {
    dataInizio = "";
  }

  let dataFine = data.data_fine;
  if (dataFine) {
    const dateObjFine = new Date(dataFine);
    if (dateObjFine.toString() !== "Invalid Date") {
      const dayFine = dateObjFine.getDate().toString().padStart(2, "0");
      const monthFine = (dateObjFine.getMonth() + 1)
        .toString()
        .padStart(2, "0"); // Nota: i mesi in JavaScript partono da 0, quindi aggiungiamo 1 al valore restituito
      const yearFine = dateObjFine.getFullYear().toString();
      dataFine = `${dayFine}/${monthFine}/${yearFine}`;
    } else {
      dataFine = "";
    }
  } else {
    dataFine = "";
  }
  const override = css`
    display: block;
    margin: 0 auto;
    border-color: red;
  `;
  const isDesktop = useMediaQuery({
    query: "(min-width: 1024px)",
  });
  return (
    <div className={"h-screen overflow-hidden"}>
      {isLoading ? (
        <div className="flex justify-center items-center h-screen">
          <BarLoader color={"#2b6cb0"} css={override} size={50} />
        </div>
      ) : (
        <>
          <div className={"h-50vh relative"}>
            <div
              className={
                "flex flex-wrap h-[28vh] content-center relative flex overflow-hidden top-0 z-10"
              }
            >
              <div className="absolute inset-0 flex justify-start items-center pl-8 pr-8">
                <div className="flex flex-wrap items-start -mt-10">
                  {isDesktop ? <></> : <BackButton sfondo={"true"} />}
                  {data && (
                    <p
                      className="text-white text-left"
                      dangerouslySetInnerHTML={{ __html: data.body }}
                    ></p>
                  )}
                </div>
              </div>
            </div>
            {data && data.immagine_anteprima && (
              <img
                src={`https://backend.biassono.bbsitalia.com${data.immagine_anteprima}`}
                className={"w-full h-full object-cover absolute top-0 left-0"}
              />
            )}
          </div>
          <div className={"text-left p-8 h-[50vh] lg:h-[40vh] overflow-auto"}>
            {data && data.titolo && isDesktop ? (
              <h1
                className={
                  "text-monrealeRed font-roboto_slab text-[40px] font-bold mb-3"
                }
              >
                {data.titolo}
              </h1>
            ) : (
              <h1
                className={
                  "text-monrealeRed font-roboto_slab text-[25px] font-bold mb-3"
                }
              >
                {data.titolo}
              </h1>
            )}
            <div className={"flex items-start mb-3"}>
              {data && data.tipologia_dove_dormire_label && (
                <img
                  src={doveDormire}
                  alt={"doveDormire"}
                  className={"inline-block mt-1 w-5 mr-2"}
                />
              )}
              <div className={"inline-block"}>
                {data?.tipologia_dove_dormire_label?.map((item, i) => (
                  <span className={"inline-block mb-2 mr-2"} key={i}>
                    {item}
                  </span>
                ))}
              </div>
            </div>

            {data && data.dove && (
              <div className={"flex items-start mb-3"}>
                <img
                  src={doveIcon}
                  alt={"doveIcon"}
                  className={"inline-block mt-0 w-5 mr-2"}
                />
                <div className={"inline-block"}>
                  <span className={"inline-block mb-2 break-all"}>
                    {data.dove}
                  </span>
                </div>
              </div>
            )}

            {data && dataInizio && dataFine && (
              <div className={"flex items-start mb-3"}>
                <img
                  src={calendarioIcon}
                  alt={"calendarioIcon"}
                  className={"inline-block mt-0 w-5 mr-2"}
                />
                <div className={"inline-block"}>
                  <span className={"inline-block mb-2"}>{dataInizio}</span>
                  <span className={"inline-block mb-2 ml-1 mr-1"}>-</span>
                  <span className={"inline-block mb-2"}>{dataFine}</span>
                </div>
              </div>
            )}

            <GoToDirection
              latitudine={data.latitudine}
              longitudine={data.longitudine}
            />

            {data && data.descrizione && (
              <p
                className="text-left"
                dangerouslySetInnerHTML={{ __html: data.descrizione }}
              ></p>
            )}
            {data.prezzi && (
              <div>
                <h1
                  className={
                    "break-words font-semibold mt-2 mb-1 text-monrealeRed"
                  }
                >
                  {t("prezzi")}
                </h1>
                <p
                  className="text-left"
                  dangerouslySetInnerHTML={{ __html: data.prezzi }}
                ></p>
              </div>
            )}
            {data.orari && (
              <div>
                <h1
                  className={
                    "break-words font-semibold mt-2 mb-1 text-monrealeRed"
                  }
                >
                  {t("orari")}
                </h1>
                <p
                  className="text-left"
                  dangerouslySetInnerHTML={{ __html: data.orari }}
                ></p>
              </div>
            )}
            {data.telefono && (
              <div>
                <h1
                  className={
                    "break-words font-semibold mt-2 mb-1 text-monrealeRed"
                  }
                >
                  {t("telefono")}
                </h1>
                <p
                  className="text-left"
                  dangerouslySetInnerHTML={{ __html: data.telefono }}
                ></p>
              </div>
            )}
            {data.email.length > 0 && (
              <div>
                <h1
                  className={
                    "break-words font-semibold mt-2 mb-1 text-monrealeRed"
                  }
                >
                  {t("email")}
                </h1>
                <p
                  className="text-left"
                  dangerouslySetInnerHTML={{ __html: data.email }}
                ></p>
              </div>
            )}
            {data.pec.length > 0 && (
              <div>
                <h1
                  className={
                    "break-words font-semibold mt-2 mb-1 text-monrealeRed"
                  }
                >
                  {t("pec")}
                </h1>
                <p
                  className="text-left"
                  dangerouslySetInnerHTML={{ __html: data.pec }}
                ></p>
              </div>
            )}
            {data.sito_web.length > 0 && isDesktop && (
              <div>
                <h1
                  className={
                    "break-words font-semibold mt-2 mb-1 text-monrealeRed"
                  }
                >
                  {t("sito")}
                </h1>
                <p
                  className="text-left pointer-none"
                  dangerouslySetInnerHTML={{ __html: data.sito_web }}
                ></p>
              </div>
            )}
            {data.sito_web.length > 0 && !isDesktop && (
              <div>
                <h1
                  className={
                    "break-words font-semibold mt-2 mb-1 text-monrealeRed"
                  }
                >
                  {t("sito")}
                </h1>
                <a
                  className="text-justify"
                  dangerouslySetInnerHTML={{ __html: data.sito_web }}
                  onClick={(e) => openExternalLink(data.sito_web, e)}
                ></a>
              </div>
            )}
            {galleria && <Gallery images={data.nodo} />}
          </div>

          {isDesktop ? (
            <div
              className={
                "bg-monrealeRed h-[10vh] b-t-l-r-15 content-center flex flex-wrap items-center p-8"
              }
            >
              <BackButtonWhite sfondo={"true"} />
            </div>
          ) : (
            <div className={"hidden"} />
          )}
        </>
      )}
    </div>
  );
};

import React, { useState, useEffect } from "react";
import { SearchInput } from "./components/SearchInput";
import sfondoHeaderImage from "../assets/pexels-976866.png";
import { BackButton } from "../components/UI/BackButton";
import { Link } from "react-router-dom";
import doveDormireWhite from "../assets/icons/doveDormireWhite.svg";

import doveDormireIcon from "../assets/icons/doveDormireWhite.svg";
import doveMangiareIcon from "../assets/icons/doveMangiareWhite.svg";
import museiECulturaIcon from "../assets/icons/museiECulturaWhite.svg";
import eventiERassegneIcon from "../assets/icons/eventiERassegneWhite.svg";
import shoppingIcon from "../assets/icons/shoppingWhite.svg";
import itinerariIcon from "../assets/icons/itinerariTuristiciWhite.svg";
import percorsiIcon from "../assets/icons/itinerariTuristiciWhite.svg";
import newsIcon from "../assets/icons/newsWhite.svg";
import comuneETerritorioIcon from "../assets/icons/comuneETerritorioWhite.svg";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

function CategoryFilter({ onFilter }) {
  const { t } = useTranslation();

  const [category, setCategory] = useState("");

  const handleCategoryChange = (event) => {
    const value = event.target.value;
    setCategory(value);
    onFilter(value, false);
  };

  const location = useLocation();

  const getQueryParams = () => {
    const queryParams = new URLSearchParams(location.search);
    return {
      category: queryParams.get("category"),
    };
  };
  const { category: categoryFromUrl } = getQueryParams();

  useEffect(() => {
    const value = categoryFromUrl;
    setCategory(value);
    onFilter(value, true);

    if (categoryFromUrl === null) {
      setCategory("");
      onFilter("", true);
    }
  }, []);

  // useEffect(() => {
  //     This code will run every time typeParam changes
  // const hashParams = window.location.hash.substr(1);
  // const params = new URLSearchParams(hashParams);
  // setCategory(params.get('type'));
  // if (category) {
  //     console.log(category);
  // }
  // }, [category]);

  return (
    <div
      className={"relative w-full flex gap-6 snap-x overflow-x-auto pb-7 -mx-3"}
    >
      {/*<label htmlFor="category-filter">Categoria:</label>*/}
      {/*<select id="category-filter" value={category} onChange={handleCategoryChange}>*/}
      {/*<div className="snap-center shrink-0">*/}
      {/*    <div className="shrink-0 w-4 sm:w-48"></div>*/}
      {/*</div>*/}
      <div className="snap-center shrink-0 first:pl-8 last:pr-8">
        <button
          className={
            category === ""
              ? "bg-monrealeRed text-white px-2 py-3 rounded-[6px]  snap-center"
              : "bg-white text-monrealeRed border-monrealeRed px-2 py-3 rounded-[6px]  snap-center border-2"
          }
          onClick={handleCategoryChange}
          value=""
        >
          {t("tutte")}
        </button>
      </div>
      <div className="snap-center shrink-0 first:pl-8 last:pr-8">
        <button
          className={
            category === "dove_dormire"
              ? "bg-monrealeRed text-white px-2 py-3 rounded-[6px]  snap-center"
              : "bg-white text-monrealeRed border-monrealeRed px-2 py-3 rounded-[6px]  snap-center border-2"
          }
          onClick={handleCategoryChange}
          value="dove_dormire"
        >
          {t("dovedormire")}
        </button>
      </div>
      <div className="snap-center shrink-0 first:pl-8 last:pr-8">
        <button
          className={
            category === "dove_mangiare"
              ? "bg-monrealeRed text-white px-2 py-3 rounded-[6px]  snap-center"
              : "bg-white text-monrealeRed border-monrealeRed px-2 py-3 rounded-[6px]  snap-center border-2"
          }
          onClick={handleCategoryChange}
          value="dove_mangiare"
        >
          {t("dovemangiare")}
        </button>
      </div>
      <div className="snap-center shrink-0 first:pl-8 last:pr-8">
        <button
          className={
            category === "musei_e_cultura"
              ? "bg-monrealeRed text-white px-2 py-3 rounded-[6px]  snap-center"
              : "bg-white text-monrealeRed border-monrealeRed px-2 py-3 rounded-[6px]  snap-center border-2"
          }
          onClick={handleCategoryChange}
          value="musei_e_cultura"
        >
          {t("museiecultura")}
        </button>
      </div>
      <div className="snap-center shrink-0 first:pl-8 last:pr-8">
        <button
          className={
            category === "comune_e_territorio"
              ? "bg-monrealeRed text-white px-2 py-3 rounded-[6px]  snap-center"
              : "bg-white text-monrealeRed border-monrealeRed px-2 py-3 rounded-[6px]  snap-center border-2"
          }
          onClick={handleCategoryChange}
          value="comune_e_territorio"
        >
          {t("comuneeterritorio")}
        </button>
      </div>
      {/* <div className="snap-center shrink-0 first:pl-8 last:pr-8">
        <button
          className={
            category === "percorsi_turistici"
              ? "bg-monrealeRed text-white px-2 py-3 rounded-[6px]  snap-center"
              : "bg-white text-monrealeRed border-monrealeRed px-2 py-3 rounded-[6px]  snap-center border-2"
          }
          onClick={handleCategoryChange}
          value="percorsi_turistici"
        >
          {t("itinerarituristici")}
        </button>
      </div> */}
      <div className="snap-center shrink-0 first:pl-8 last:pr-8">
        <button
          className={
            category === "sapori_e_shopping"
              ? "bg-monrealeRed text-white px-2 py-3 rounded-[6px]  snap-center"
              : "bg-white text-monrealeRed border-monrealeRed px-2 py-3 rounded-[6px]  snap-center border-2"
          }
          onClick={handleCategoryChange}
          value="sapori_e_shopping"
        >
          {t("saporieshopping")}
        </button>
      </div>
      {/* <div className="snap-center shrink-0 first:pl-8 last:pr-8">
        <button
          className={
            category === "news"
              ? "bg-monrealeRed text-white px-2 py-3 rounded-[6px]  snap-center"
              : "bg-white text-monrealeRed border-monrealeRed px-2 py-3 rounded-[6px]  snap-center border-2"
          }
          onClick={handleCategoryChange}
          value="news"
        >
          {t("news")}
        </button>
      </div> */}
      {/* <div className="snap-center shrink-0 first:pl-8 last:pr-8">
        <button
          className={
            category === "rassegna"
              ? "bg-monrealeRed text-white px-2 py-3 rounded-[6px]  snap-center"
              : "bg-white text-monrealeRed border-monrealeRed px-2 py-3 rounded-[6px]  snap-center border-2"
          }
          onClick={handleCategoryChange}
          value="rassegna"
        >
          {t("rassegna")}
        </button>
      </div> */}
      <div className="snap-center shrink-0 first:pl-8 last:pr-8">
        <button
          className={
            category === "eventi"
              ? "bg-monrealeRed text-white px-2 py-3 rounded-[6px]  snap-center"
              : "bg-white text-monrealeRed border-monrealeRed px-2 py-3 rounded-[6px]  snap-center border-2"
          }
          onClick={handleCategoryChange}
          value="eventi"
        >
          {t("eventi")}
        </button>
      </div>
      {/* <div className="snap-center shrink-0 first:pl-8 last:pr-8">
        <button
          className={
            category === "punti_di_interesse"
              ? "bg-monrealeRed text-white px-2 py-3 rounded-[6px]  snap-center"
              : "bg-white text-monrealeRed border-monrealeRed px-2 py-3 rounded-[6px]  snap-center border-2"
          }
          onClick={handleCategoryChange}
          value="punti_di_interesse"
        >
          {t("puntidiinteresse")}
        </button>
      </div> */}
      {/*</select>*/}
    </div>
  );
}

function SearchResults() {
  const { t } = useTranslation();
  const currentLanguage = localStorage.getItem("i18nextLng")?.toLowerCase();

  const [results, setResults] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [type, setType] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    async function fetchData() {
      const response = await fetch(
        `https://backend.biassono.bbsitalia.com/${currentLanguage}/api/search`
      );
      const data = await response.json();
      setResults(data);
      setFilteredResults(data);
    }
    fetchData();
  }, []);

  const handleSearch = (searchTerm, type, fromUrl = false) => {
    const filtered = results.filter(
      (result) =>
        result.titolo?.toLowerCase().includes(searchTerm.toLowerCase()) &&
        (type === "" || result.type?.toLowerCase() === type.toLowerCase())
    );
    setFilteredResults(filtered);
    setType(type);
    setSearchTerm(fromUrl ? "" : searchTerm);
  };

  const getDetailLink = (location) => {
    if (!location || !location.type || !location.id) {
      return null;
    }
    let link = null;
    switch (location.type) {
      case "eventi":
        link = `/eventi-e-rassegne/${location.id}`;
        break;
      case "rassegna":
        link = `/eventi-e-rassegne/${location.id}`;
        break;
      case "news":
        link = `/news/${location.id}`;
        break;
      case "musei_e_cultura":
        link = `/musei-e-cultura/${location.id}`;
        break;
      case "punti_di_interesse":
        link = `/punti-di-interesse/${location.id}`;
        break;
      case "dove_mangiare":
        link = `/dove-mangiare/${location.id}`;
        break;
      case "dove_dormire":
        link = `/dove-dormire/${location.id}`;
        break;
      case "sapori_e_shopping":
        link = `/sapori-e-shopping/${location.id}`;
        break;

      case "percorsi_turistici":
        link = `/itinerari-turistici/${location.id}`;
        break;

      case "comune_e_territorio":
        link = `/comune-e-territorio/${location.id}`;
        break;
      default:
        link = `/dettaglio/${location.id}`;
        break;
    }

    return link;
  };
  const getDetailImage = (location) => {
    if (!location || !location.type || !location.id) {
      return null;
    }
    let src = null;
    switch (location.type) {
      case "eventi":
        src = eventiERassegneIcon;
        break;
      case "rassegna":
        src = eventiERassegneIcon;
        break;
      case "news":
        src = newsIcon;
        break;
      case "musei_e_cultura":
        src = museiECulturaIcon;
        break;
      case "punti_di_interesse":
        src = percorsiIcon;
        break;
      case "dove_mangiare":
        src = doveMangiareIcon;
        break;
      case "dove_dormire":
        src = doveDormireIcon;
        break;
      case "sapori_e_shopping":
        src = shoppingIcon;
        break;
      case "percorsi_turistici":
        src = itinerariIcon;
        break;
      case "comune_e_territorio":
        src = comuneETerritorioIcon;
        break;
    }

    return src;
  };

  return (
    <section id={"cerca"}>
      <div
        className={
          "bg-monrealeRed flex flex-wrap h-[20vh] content-center relative flex overflow-hidden"
        }
      >
        <div className="absolute inset-0 flex justify-start items-center pl-5 pr-5">
          <div className={"flex flex-wrap items-center"}>
            <BackButton sfondo={"true"} />
            <span className={"text-white font-semibold text-[20px] ml-4"}>
              {t("cerca")}
            </span>
          </div>
        </div>
      </div>
      <div className={"m-5"}>
        <SearchInput
          onSearch={(searchTerm) => handleSearch(searchTerm, type)}
          setSearchTerm={setSearchTerm}
        />
      </div>
      <CategoryFilter
        onFilter={(type, fromUrl) => handleSearch(searchTerm, type, fromUrl)}
      />
      <div className={"h-[62vh] overflow-auto"}>
        {/*<h2>Risultati di ricerca:</h2>*/}
        <ul className={"flex flex-wrap mx-4"}>
          {filteredResults
            .filter(
              (result) =>
                !type || result.type.toLowerCase() === type.toLowerCase()
            )
            .map((result, index) => (
              <li key={index} className={"relative flex flex-wrap w-1/2"}>
                <div
                  key={index}
                  className={"flex flex-wrap  w-full rounded-[10px] p-2 mb-5"}
                >
                  <Link to={`${getDetailLink(result)}`} className={"w-full"}>
                    <div
                      className={
                        "shadow-monrealeShadow rounded-[10px] w-full relative h-full"
                      }
                    >
                      <div
                        className={
                          "absolute bg-monrealeRed top-0 right-0 p-2 rounded-[10px]"
                        }
                      >
                        <img
                          src={`${getDetailImage(result)}`}
                          className={"                            w-6 h-6"}
                        />
                      </div>
                      <img
                        src={`https://backend.biassono.bbsitalia.com${result.immagine_anteprima}`}
                        className={
                          "w-full h-[250px] lg:h-[28rem] object-cover rounded-[10px]"
                        }
                      />
                      <div
                        className={
                          "flex flex-wrap justify-center items-center px-4 py-2"
                        }
                      >
                        <h1
                          className={
                            "text-monrealeRed text-[15px] font-roboto_slab font-bold pt-3 pb-3"
                          }
                        >
                          {result.titolo} <br />
                          {/*{result.type}*/}
                        </h1>
                      </div>
                    </div>
                  </Link>
                </div>
              </li>
            ))}
        </ul>
      </div>
    </section>
  );
}

export default SearchResults;
